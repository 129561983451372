<template>
  <form class="form form-send">
    <common-app-input
      v-for="input in inputs"
      :key="input.name"
      v-model="input.modelValue"
      :name="input.name"
      :type="input.type"
      :placeholder="input.placeholder"
      :textarea="input?.textarea"
    />
    <common-app-checkbox
      :model-value="selected"
      name="input-agreement"
      :label="t('form.agreement')"
      :value="false"
      @update:modelValue="selected = $event"
    />
    <common-app-form-button :label="$t('button.send')" @click.prevent="submitForm"/>
  </form>
</template>

<script setup lang="ts">
const { $toast } = useNuxtApp();
const { $api } = useNuxtApp();
const { t } = useI18n();
const { locale } = useI18n();

const inputs = ref([
  {
    modelValue: '',
    name: 'input-name',
    type: 'text',
    placeholder: t('form.name'),
    textarea: false
  },
  {
    modelValue: '',
    name: 'input-email',
    type: 'email',
    placeholder: t('form.email'),
    textarea: false
  },
  {
    modelValue: '',
    name: 'input-message',
    type: '',
    placeholder: t('form.message'),
    textarea: true
  }
]);

const selected = ref(false);

function isEmailValid (value = '') {
  return String(value)
    .toLowerCase()
    .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

const submitForm = async () => {
  let _source = null;
  if (process.client) {
  // @ts-expect-error
    const _sbjs: any = sbjs;
    _source =  JSON.stringify(_sbjs?.get?.first) || null;
  }
  const formData = {
    name: inputs.value?.[0]?.modelValue,
    email: inputs.value?.[1]?.modelValue,
    message: inputs.value?.[2]?.modelValue,
    source: _source,
  }

  if (!formData.name?.length) {
    locale.value === 'en' ? $toast.error('enter your name') : $toast.error('заполните имя');
    return;
  }
  if (!formData.email?.length) {
    locale.value === 'en' ? $toast.error('enter your email') : $toast.error('заполните email');
    return;
  }
  if (!isEmailValid(formData.email)) {
    locale.value === 'en' ? $toast.error('check your email') : $toast.error('проверьте заполнение email');
    return;
  }
  if (!formData.message?.length) {
    locale.value === 'en' ? $toast.error('you forgot to write a message') : $toast.error('вы забыли написать сообщение');
    return;
  }
  if (selected.value === false) {
    locale.value === 'en' ? $toast.error('you did not agree with the privacy policy') : $toast.error('вы не согласились с политикой о конфиденциальности');
    return
  }
  try {
    const response: any = await $api('feedback/sendFeedback', {
      method: 'POST',
      body: {
        ...formData
      }
    });
    if (response?.status === 'ok') {
      inputs.value.forEach((t) => t.modelValue = '');
      selected.value = false;
      locale.value === 'en' ? $toast.success('successfully sent') : $toast.success('успешно отправлено');
    } else {
      if (response.errors && response.errors.message) {
        const errorMessage = response.errors.email[0];
        locale.value === 'en' ? $toast.error(errorMessage) : $toast.error('Ошибка: ' + errorMessage);
      }
    }
  } catch (error) {
    console.error(error);
    locale.value === 'en' ? $toast.error('invalid values') : $toast.error('некорректные данные');
  }
}
</script>

<style scoped>

</style>
