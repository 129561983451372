<template>
  <section class="section section__card">
    <div class="container">
      <NuxtImg
        format="webp"
        src="/img/card-items.png"
        loading="lazy"
        sizes="100vw sm:50vw md:400px"
      />
      <div class="section__card__content">
        <h2>{{ $t('earnMoney.title') }}</h2>
        <p>{{ $t('earnMoney.text') }}</p>
        <nuxt-link
          to="https://smsbower.net/partnership?utm_source=smscode&utm_medium=cpc&utm_campaign=smscode_main"
          target="_blank"
        >
          {{ $t('button.more') }}
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.section {
  &__card {
    padding: 120px 0 130px;
    background-color: var(--white-light);
    background-image: url("/img/card-bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 200px;

      > * {
        flex: 1;
      }

      > img {
        flex: 0 1 30%;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 60px;
      font-size: 24px;
      color: var(--white);

      h2 {
        margin: 0 0 10px !important;
        font-weight: 700;
        font-size: 48px;
        line-height: normal;
        color: var(--white);
        text-align: initial !important;
      }

      a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        padding: 16px 32px;
        border-radius: 64px;
        background: var(--primary-90);
        font-size: 16px;
        font-weight: 500;
        color: var(--white);
        transition: var(--transition);

        &:hover {
          background-color: var(--primary-500);
        }
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 60px 0 80px;

      .container {
        flex-direction: column;
        gap: 60px;

        > img {
          flex: 0 1 100%;
        }
      }

        &__content {
          gap: 30px;
          font-size: 16px;

          h2 {
            font-size: 30px;
          }

          a {
            padding: 12px 26px;
            border-radius: 60px;
            font-size: 14px;
          }
        }
    }
  }
}
</style>
