<template>
  <section class="how-it-work custom-section">
    <div class="container">
      <h2 class="title">{{ $t('howItWorks.title') }}</h2>
      <div class="content-wrapper">
        <div
          v-for="item in infoList"
          :key="item.id"
          class="card"
        >
          <div class="icon">
            <img
              :src="item.img"
              :alt="item.title"
            />
          </div>

          <div class="title">{{ item.title }}</div>
          <div class="description">{{ item.description }}</div>
        </div>
      </div>
      <home-more-info />
    </div>
  </section>
</template>

<script setup lang="ts">
const HomeMoreInfo =defineAsyncComponent(() => import('~/components/home/more-info/HomeMoreInfo.vue'));

const { t } = useI18n();

const infoList = [
  {
    title: t('howItWorks.block1.title'),
    description: t('howItWorks.block1.text'),
    img: useAsset('img/how-it-work/authorization.png'),
    id: 1
  },
  {
    title: t('howItWorks.block2.title'),
    description: t('howItWorks.block2.text'),
    img: useAsset('img/how-it-work/get-number.png'),
    id: 2
  },
  {
    title: t('howItWorks.block3.title'),
    description: t('howItWorks.block3.text'),
    img: useAsset('img/how-it-work/get-code.png'),
    id: 3
  }
];
</script>

<style scoped lang="scss">
.how-it-work {
	& .content-wrapper {
		display: flex;
		gap: 120px;

		& > div {
			flex: 1;
		}
	}

	& .card {
		display: flex;
		flex-direction: column;
		align-items: center;

		& .icon {
			height: 110px;
			min-height: 110px;
			max-height: 110px;
			flex: 1;
			width: fit-content;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 30px;

			& img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        object-position: center;
      }
		}

		& .title {
			color: var(--violet);
			text-align: center;
			font-size: 36px;
			font-style: normal;
			font-weight: 700;
			line-height: 110%;
			margin-bottom: 52px;
			display: flex;
		}

		& .description {
			text-align: center;
			font-size: 20px;
			font-weight: 500;
			line-height: 110%;
			display: flex;

		}
	}
}

@media (max-width: 1200px) {
	.how-it-work {
		& .content-wrapper {
			display: flex;
			gap: 60px;

			& > div {
				flex: 1;
			}
		}
	}
}

@media (max-width: 1024px) {
	.how-it-work {
		& .content-wrapper {
			gap: 30px;

			& > div {
				flex: 1;
			}
		}
		& .card {
			& .title {
				font-size: 25px;
				line-height: 110%;
			}
    }
	}
}

@media (max-width: 1024px) {
	.how-it-work {
		& .content-wrapper {
			gap: 20px;
			flex-wrap: wrap;

			& > div {
				flex: 1;
			}
		}
	}
}
@media screen and (max-width: 860px) {
  .how-it-work {
		& .content-wrapper {
			gap: 20px;
			flex-wrap: wrap;

			& > div {
				flex: 1;
			}
		}
	}
}
@media screen and (max-width: 640px) {
  .how-it-work {
	display: flex;
    & .content-wrapper {
      max-width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(1, 360px);
      grid-auto-rows: auto;
      row-gap: 86px;
      margin: 0 auto;
    }

    & .card {
      & .title {
        font-size: 25px;
        line-height: 110%;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .how-it-work  {
    & .content-wrapper {
      grid-template-columns: minmax(200px, 1fr);
    }
  }
}
</style>
